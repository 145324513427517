.xgplayer-skin-xgplayer-gga {
  .xgplayer-playnext {
    position: relative;
    order: 1;
    display: block;
    cursor: pointer;
    top: -2px;
    .xgplayer-icon {
      div {
        position: absolute;
      }
    }
    .xgplayer-tips {
      .xgplayer-tip-playnext {
        display: block;
      }
    }
    &:hover {
      opacity: 0.85;
      .xgplayer-tips {
        display: block;
      }
    }
  }
}
.xgplayer-lang-is-en {
  .xgplayer-playnext {
    .xgplayer-tips {
      margin-left: -25px;
    }
  }
}
.xgplayer-lang-is-jp {
  .xgplayer-playnext {
    .xgplayer-tips {
      margin-left: -38px;
    }
  }
}
.xgplayer-skin-xgplayer-gga.xgplayer-playnext-inactive {
  .xgplayer-playnext {
    display: none;
  }
}
