.gvp-player-expanded {
  border-bottom: 2px solid #ab8c2f;
}

.gvp-player-hidden {
  display: none;
}

.gvp-player-relative {
  position: relative;
}

.gvp-player-controls-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.4rem;
  align-items: center;
}

.gvp-player-overlay-wrapper {
  position: absolute;
  top: 0;
  left: 0;
}

.gvp-player-invisible {
  visibility: hidden;
}
