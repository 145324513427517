.gga-xg-definition-tips {
  position: absolute;
  top: calc(50% + 60px);
  left: 50%;
  z-index: 115;
  display: none;
  padding: 5px 10px;
  color: white;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
  transform: translate(-50%, -50%);
}

.gga-xg-definition-tips.show {
  display: block;
}
