.xgplayer-skin-xgplayer-gga {
  .xgplayer-time {
    order: 2;
    font-family: ArialMT;
    font-size: 13px;
    color: #fff;
    line-height: 40px;
    height: 40px;
    text-align: center;
    display: inline-block;
    margin: auto 8px;
    span {
      color: rgba(255, 255, 255, 0.50);
    }
    .xgplayer-time-current {
      color: #fff;
    }
    .xgplayer-time-current::after {
      content: "/";
      display: inline-block;
      padding: 0 3px;
    }
  }
}
