@tailwind base;
@tailwind components;
@tailwind utilities;

$pc-nav-height: 64px;

@layer base {
  @font-face {
    font-family: 'Nanum Barun Gothic';
    font-style: normal;
    font-weight: light;
    src: local('Nanum Barun Gothic Light'),
      url('/fonts/NanumBarunGothic/NanumBarunGothicLight.woff2') format('woff2'),
      url('/fonts/NanumBarunGothic/NanumBarunGothicLight.woff') format('woff');
  }

  @font-face {
    font-family: 'Nanum Barun Gothic';
    font-style: normal;
    font-weight: normal;
    src: local('Nanum Barun Gothic'),
      url('/fonts/NanumBarunGothic/NanumBarunGothic.woff2') format('woff2'),
      url('/fonts/NanumBarunGothic/NanumBarunGothic.woff') format('woff');
  }

  @font-face {
    font-family: 'Nanum Barun Gothic';
    font-style: normal;
    font-weight: bold;
    src: local('Nanum Barun Gothic Bold'),
      url('/fonts/NanumBarunGothic/NanumBarunGothicBold.woff2') format('woff2'),
      url('/fonts/NanumBarunGothic/NanumBarunGothicBold.woff') format('woff');
  }

  @font-face {
    font-family: 'BMHANNAPro';
    font-style: normal;
    font-weight: normal;
    src: local('BMHANNAPro'), url('/fonts/BMHANNAPro.ttf') format('truetype');
  }

  //Pretendard
  @font-face {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 300;
    src: local('Pretendard Light'),
      url('https://res.gengesports.cn/user-web/fonts/pretendard/Pretendard-Light.otf')
        format('OpenType');
  }

  @font-face {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: normal;
    src: local('Pretendard'),
      url('https://res.gengesports.cn/user-web/fonts/pretendard/Pretendard-Regular.otf')
        format('OpenType');
  }

  @font-face {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 500;
    src: local('Pretendard Medium'),
      url('https://res.gengesports.cn/user-web/fonts/pretendard/Pretendard-Medium.otf')
        format('OpenType');
  }

  @font-face {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 600;
    src: local('Pretendard SemiBold'),
      url('https://res.gengesports.cn/user-web/fonts/pretendard/Pretendard-SemiBold.otf')
        format('OpenType');
  }

  @font-face {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: bold;
    src: local('Pretendard Bold'),
      url('https://res.gengesports.cn/user-web/fonts/pretendard/Pretendard-Bold.otf')
        format('OpenType');
  }

  @font-face {
    font-family: 'Fjalla One';
    font-style: normal;
    font-weight: normal;
    src: local('Fjalla One'),
      url('https://res.gengesports.cn/user-web/fonts/Fjalla_One/FjallaOne-Regular.ttf')
        format('truetype');
  }

  @font-face {
    font-family: 'douyuZGT';
    font-style: normal;
    font-weight: normal;
    src: local('douyuZGT'), url('/fonts/douyuZGT.ttf') format('truetype');
  }
}
// 重写tailwind form的global样式
[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  box-shadow: unset;
}

[type='checkbox'],
[type='radio'] {
  color: #ab8c2f;
}

[type='checkbox']:focus,
[type='radio']:focus {
  box-shadow: unset;
}

[type='checkbox']:checked.mantine-Switch-input {
  background-image: unset;
}

.gga-page-view .pdf-link {
  background-image: url('/images/pdf.svg');
  background-repeat: no-repeat;
  background-position: 0 center;
  padding-left: 20px;
}

// for react modal global setting http://reactcommunity.org/react-modal/styles/
.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content,
.ReactModal__Overlay {
  &:focus-visible {
    outline: none;
  }
}

@layer utilities {
  @variants responsive {
    .h-content {
      min-height: calc(100vh - #{$pc-nav-height});
    }

    .pc-height {
      height: calc(100vh - #{$pc-nav-height});
    }
  }

  .ff-fjo {
    font-family: 'Fjalla One';
  }

  .super {
    vertical-align: super;
  }

  .sub {
    vertical-align: sub;
  }

  .hide-scroll {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
  }
}

@layer components {
  .gold-bordered {
    background: url('/images/border-bg@2x.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .dotdotdot-loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: '\2026'; /* ascii code for the ellipsis character */
    width: 0;
    margin-left: 2px;
  }

  .full-screen {
    @apply h-screen lg:pc-height;
  }
}

@keyframes ellipsis {
  to {
    width: 20px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;
  }
}
