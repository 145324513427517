.xgplayer-skin-xgplayer-gga {
  .xgplayer-reload {
    position: relative;
    order: 1;
    display: block;
    width: 40px;
    height: 40px;
    cursor: pointer;
    .xgplayer-icon {
      margin-top: 7px;
      width: 26px;
      div {
        position: absolute;
      }
    }
    .xgplayer-tips {
      margin-left: -22px;
      .xgplayer-tip-reload {
        display: block;
      }
    }
    &:hover {
      opacity: 0.85;
      .xgplayer-tips {
        display: block;
      }
    }
  }
}
.xgplayer-lang-is-en {
  .xgplayer-reload {
    .xgplayer-tips {
      margin-left: -26px;
    }
  }
}
.xgplayer-lang-is-jp {
  .xgplayer-reload {
    .xgplayer-tips {
      margin-left: -38px;
    }
  }
}
