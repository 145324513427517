@mixin mirage-hovered {
  color: #b8ccdf;
}

.event-item {
  font-size: 32px;
}

.event-item-init {
  @apply text-gold;
  &:hover {
    @apply text-gold-600;
  }
}

.event-item-submitted {
  @apply text-picton;
  &:hover {
    @include mirage-hovered;
  }
}

.event-new-feedback,
.event-item-feedbacked {
  @apply text-picton;
  &:hover {
    @include mirage-hovered;
  }

  &:before {
    content: "";
    position: absolute;
    right: 0;
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $error-color;
  }
}

.event-item-failed {
  @apply text-coral;
  &:hover {
    @apply text-red-500;
  }
}

.event-item-finished {
  color: #8d8d8d;

  &:hover {
    @apply text-gray-300;
  }
}
