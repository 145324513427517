.xgplayer-skin-xgplayer-gga {
  .xgplayer-texttrack {
    order: 7;
    width: 60px;
    height: 150px;
    z-index: 18;
    position: relative;
    outline: none;
    display: none;
    cursor: default;
    // margin-left: 10px;
    margin-top: -119px;
    // margin-bottom: 11px;
    ul {
      display: none;
      list-style: none;
      min-width: 78px;
      background: rgba(0, 0, 0, 0.54);
      border-radius: 1px;
      position: absolute;
      bottom: 30px;
      text-align: center;
      white-space: nowrap;
      left: 50%;
      transform: translateX(-50%);
      width: fit-content;
      z-index: 26;
      cursor: pointer;
      li {
        opacity: 0.7;
        font-family: PingFangSC-Regular;
        font-size: 11px;
        color: rgba(255, 255, 255, 0.80);
        width: fit-content;
        margin: auto;
        padding: 6px 13px;
        &.selected {
          color: #fff;
          opacity: 1;
        }
        &:hover {
          color: #fff;
          opacity: 1;
        }
      }
    }
    .name {
      text-align: center;
      font-family: PingFangSC-Regular;
      font-size: 13px;
      line-height: 20px;
      height: 20px;
      cursor: pointer;
      color: rgba(255, 255, 255, 0.80);
      position: absolute;
      bottom: 0;
      width: 60px;
      height: 20px;
      line-height: 20px;
      background: rgba(0, 0, 0, 0.38);
      border-radius: 10px;
      display:inline-block;
      vertical-align:middle;
    }
  }
}

xg-text-track {
  transition: bottom .3s ease;
}

.xgplayer-skin-xgplayer-gga.xgplayer-texttrack-active {
  .xgplayer-texttrack {
    ul {
      display: block;
    }
  }
}

.xgplayer-skin-xgplayer-gga.xgplayer-is-texttrack {
  .xgplayer-texttrack {
    display: block;
  }
}
