.xgplayer-skin-xgplayer-gga {
  .xgplayer-icon {
      display: block;
      width: 40px;
      height: 40px;
      overflow: hidden;
      fill: #fff;
      svg {
        position: absolute;
      }
  }
}
