.gvp-controls-color {
  width: 36px;
  height: 100%;
  border-radius: 2px;
}

.gvp-controls-swatch {
  display: inline-block;
  height: 100%;
  padding: 3px;
  background: #fff;
  border: 1px #999 solid;
  border-radius: 2px;
  cursor: pointer;
}

.gvp-controls-swatch-disabled {
  background: #eee;
  border: 1px #ccc solid;
  cursor: not-allowed;
  display: none;
}

.gvp-controls-swatch-wrapper {
  position: relative;
  height: 32px;
}

.gvp-controls-popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  transform: translate(0, -100%);
}

.gvp-controls-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.gvp-controls-control-button {
  margin: 0 0.2rem 0 0.2rem;
  cursor: pointer;
  vertical-align: baseline;

  &.text-button {
    padding: 0.25rem 0.75rem;
    color: rgb(31, 41, 55);
    border: 1px solid rgb(31, 41, 55);
  }

  &.icon-button {
    padding: 0.2rem;
  }

  &:hover {
    background-color: rgb(243, 244, 246);
  }

  &:disabled {
    display: none;
    color: rgb(156, 163, 175);
    background-color: rgb(229, 231, 235);
    border-color: rgb(229, 231, 235);
    cursor: not-allowed;
  }
}

.gvp-playback {
  &-play {
    margin-right: 8px;
    padding: 0 4px;
    color: #777777;
    cursor: pointer;

    &:hover {
      color: #333333;
    }
  }

  &-container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 8px 0;
  }

  &-progress-wrapper {
    flex: 1;
  }

  &-exit {
    margin-left: 8px;
    padding: 0.25rem 0.75rem;
    color: rgb(31, 41, 55);
    border: 1px solid rgb(31, 41, 55);
    cursor: pointer;

    &:hover {
      background-color: rgb(243, 244, 246);
    }

    &:disabled {
      color: rgb(156, 163, 175);
      background-color: rgb(229, 231, 235);
      border-color: rgb(229, 231, 235);
      cursor: not-allowed;
    }
  }
}
