.xgplayer-skin-xgplayer-gga {
    .xgplayer-miniplayer {
      order: 9;
      position: relative;
      outline: none;
      display: block;
      cursor: pointer;
      // margin-left: 10px;
      // margin-top: 9px;
      // margin-bottom: 11px;
      height: 20px;
      top: 10px;
      .name {
        text-align: center;
        font-family: PingFangSC-Regular;
        font-size: 13px;
        line-height: 20px;
        height: 20px;
        color: rgba(255, 255, 255, 0.80);
        span{
          width: 80px;
          height: 20px;
          line-height: 20px;
          background: rgba(0, 0, 0, 0.38);
          border-radius: 10px;
          display:inline-block;
          vertical-align:middle;
        }
      }
    }
  
    .xgplayer-miniplayer-lay{
      position: absolute;
      top: 26px;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 130;
      cursor: pointer;
      background-color: transparent;
      display: none;
      div{
        width: 100%;
        height: 100%;
      }
    }
  
    .xgplayer-miniplayer-drag{
      cursor: move;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 26px;
      line-height: 26px;
      background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
      z-index: 130;
      display: none;
      .drag-handle {
        width: 100%;
      }
    }
  }
  
  .xgplayer-skin-xgplayer-gga.xgplayer-miniplayer-active {
    position: fixed;
    right: 0;
    bottom: 200px;
    width: 320px;
    height: 180px;
    z-index: 110;
    .xgplayer-controls {
      display: none;
    }
    .xgplayer-danmu {
      display: none;
    }
    .xgplayer-miniplayer-lay{
      display: block;
    }
  }
  
  .xgplayer-skin-xgplayer-gga.xgplayer-miniplayer-active {
    .xgplayer-miniplayer-drag {
      display: flex;
    }
  }
  
  .xgplayer-skin-xgplayer-gga.xgplayer-inactive {
    .xgplayer-miniplayer-drag {
      display: none;
    }
  }
  .lang-is-jp {
    .xgplayer-miniplayer {
      .name {
        span{
          width: 70px;
          height: 20px;
        }
      }
    }
  }
  