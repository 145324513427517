.xgplayer-skin-xgplayer-gga {
  .xgplayer-screenshot {
    order: 11;
    position: relative;
    outline: none;
    display: block;
    cursor: pointer;
    // margin-left: 10px;
    // margin-top: 9px;
    // margin-bottom: 11px;
    height: 20px;
    top: 10px;
    .name {
      text-align: center;
      font-family: PingFangSC-Regular;
      font-size: 13px;
      line-height: 20px;
      height: 20px;
      color: rgba(255, 255, 255, 0.80);
      span{
        width: fit-content;
        padding: 0 10px;
        height: 20px;
        line-height: 20px;
        background: rgba(0, 0, 0, 0.38);
        border-radius: 10px;
        display:inline-block;
        vertical-align:middle;
      }
    }
  }
}
.xgplayer-lang-is-en, .xgplayer-lang-is-jp{
  .xgplayer-screenshot {
    .name {
      span{
        width: 75px;
        height: 20px;
      }
    }
  }
}
