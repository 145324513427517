.gga-page-view p {
  min-height: 1em;
}

.gga-page-view .image-wrap img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.gga-page-view ul,
.gga-page-view ol {
  margin: 16px 0;
  padding: 0;
}

.gga-page-view blockquote {
  margin: 0 0 10px 0;
  padding: 15px 20px;
  background-color: #f1f2f3;
  border-left: solid 5px #ccc;
  color: #666;
  font-style: italic;
}

.gga-page-view pre {
  max-width: 100%;
  max-height: 100%;
  margin: 10px 0;
  padding: 15px;
  overflow: auto;
  background-color: #f1f2f3;
  border-radius: 3px;
  color: #666;
  font-family: monospace;
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.gga-page-view pre pre {
  margin: 0;
  padding: 0;
}

.gga-page-view {
  ul {
    list-style: initial;
  }

  ol {
    list-style: decimal;
  }

  ul,
  ol {
    list-style-position: inside;

    & li::marker {
      font-size: 1.2em;
    }
  }
}
