html {
  background: transparent;
}

body {
  @apply hide-scroll;
  background: transparent;
  position: relative;
  font-size: 14px;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  color: #333;
}

html:lang(zh_CN) {
  font-family: 'PingFang SC', 'SF Pro SC', 'SF Pro Text', 'SF Pro Icons',
    'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}

html:lang(kr) {
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, 'Malgun Gothic',
    '맑은 고딕', helvetica, 'Apple SD Gothic Neo', sans-serif;
}

button:focus {
  outline: none;
}

.Toastify__toast-container {
  opacity: 0.9;
}

.Toastify__toast {
  text-indent: 10px;
  min-height: 54px;
  border-radius: 5px;
  box-shadow: 0 0 12px rgb(133, 132, 133);
}

.Toastify__close-button {
  display: none;
}

.Toastify__toast-body {
  color: #fff;
}

.global-sync-loading {
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
}

a {
  text-decoration: underline;
}

.swiper-slide-img {
  max-width: unset;
  width: unset;
  height: unset;
}
