.xgplayer-skin-xgplayer-gga {
    .xgplayer-tips{
      background: rgba(0, 0, 0, 0.54);
      border-radius: 1px;
      display: none;
      position: absolute;
      font-family: PingFangSC-Regular;
      font-size: 11px;
      color: #fff;
      padding: 2px 4px;
      text-align: center;
      top: -30px;
      left: 50%;
      margin-left: -16px;
      width: auto;
      white-space: nowrap;
    }
}

.xgplayer-skin-xgplayer-gga.xgplayer-mobile {
  .xgplayer-tips{
    display: none !important;
  }
}
