.xgplayer-skin-xgplayer-gga {
    .xgplayer-screen-container {
        width: 100%;
        height: 100%;
        .xgplayer-screen-canvas {
            width: 100%;
            height: 100%;
        }
    }
}
