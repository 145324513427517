@import '~@/styles/common.module';

.header {
  .menu {
    @apply px-4 hidden md:flex;
    flex: 1 1 auto;
    flex-wrap: nowrap;
    min-width: 0;

    a {
      text-decoration: none;
    }
  }

  .link {
    // font-size: 16px;
    // color: #fff;
    // font-weight: 500;
    padding: 0 25px;
    margin: 0 10px;
    position: relative;
    display: flex;
    align-items: center;
    @extend .header-font;
    text-decoration: none;
  }

  .active {
    @apply bg-gold;
  }

  .user-info {
    z-index: 3;
    position: absolute;
    top: 100%;
    right: 0;
    border-top: 0.5em solid transparent;

    .user-box {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      border: 1px solid rgba(0, 0, 0, 0.6);
      background: #fff;
      @apply py-2 px-4;

      .user-button {
        @apply uppercase my-1;
        width: 120px;
      }
    }
  }
}

.action {
  @apply text-white no-underline h-full px-6 flex items-center;

  &:hover {
    @apply bg-brand;
  }
}

.dropdown-mobile {
  visibility: hidden;
  opacity: 0;
  transform: translate(0, -100%);
  transition: all 0.2s cubic-bezier(0, 0.52, 0, 1);

  &-show {
    opacity: 90%;
    visibility: visible;
    transform: translate(0, 0);
  }
}
