.xgplayer-skin-xgplayer-gga {
  .xgplayer-start {
    border-radius: 50%;
    display: inline-block;
    width: 70px;
    height: 70px;
    background: rgba(0, 0, 0, 0.38);
    overflow: hidden;
    text-align: center;
    line-height: 70px;
    vertical-align: middle;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 115;
    margin: -35px auto auto -35px;
    cursor: pointer;
    div{
      position: absolute;
      svg{
        fill: rgba(255, 255, 255, 0.7);
      }
    }
    .xgplayer-icon-play{
      display: block;
    }
    .xgplayer-icon-pause{
      display: none;
    }
    &:hover{
      opacity: 0.85;
    }
  }
}

.xgplayer-skin-xgplayer-gga.xgplayer-start-hide {
  .xgplayer-start {
    display: none;
  }
}
.xgplayer-skin-xgplayer-gga.xgplayer-pause.xgplayer-start-hide {
  .xgplayer-start {
    display: none;
  }
}
.xgplayer-skin-xgplayer-gga.xgplayer-playing {
  .xgplayer-start {
    display: none;
    .xgplayer-icon-play{
      display: none;
    }
    .xgplayer-icon-pause{
      display: block;
    }
  }
}
.xgplayer-skin-xgplayer-gga.xgplayer-pause {
  .xgplayer-start {
    display: inline-block;
    .xgplayer-icon-play{
      display: block;
    }
    .xgplayer-icon-pause{
      display: none;
    }
  }
}
.xgplayer-skin-xgplayer-gga.xgplayer-is-replay {
  .xgplayer-start {
    display: none;
    .xgplayer-icon-play{
      display: block;
    }
    .xgplayer-icon-pause{
      display: none;
    }
  }
}
