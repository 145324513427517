.xgplayer-skin-xgplayer-gga {
  .xgplayer-controls {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .37), rgba(0, 0, 0, .75), rgba(0, 0, 0, .75));
    z-index: 10;
  }
}

.xgplayer-skin-xgplayer-gga.xgplayer-nostart {
  .xgplayer-controls {
    display: none;
  }
}

.xgplayer-skin-xgplayer-gga.xgplayer-no-controls {
  .xgplayer-controls {
    display: none;
  }
}

.xgplayer-skin-xgplayer-gga.xgplayer-inactive {
  .xgplayer-controls {
    display: none;
  }
}
.xgplayer-skin-xgplayer-gga.xgplayer-is-live {
  .xgplayer-controls {
    .xgplayer-time,.xgplayer-progress {
      display: none;
    }
    .xgplayer-live {
      display: block;
    }
  }
}
