@import "./GreatVideoPlayer/styles.scss";
@import "./recordableDrawing/styles.scss";
@import "./components/styles.scss";
@import "./styles/index.scss";
@import "./styles/.xgplayer/skin/style/index.scss";
@import "./GreatVideoPlayer//Plugins/DefinitionTips/style.scss";

@font-face {
  font-family: "iconfont"; /* Project id 2534110 */
  src: url("//at.alicdn.com/t/font_2534110_5323kulh1g4.woff2?t=1623320217546")
      format("woff2"),
    url("//at.alicdn.com/t/font_2534110_5323kulh1g4.woff?t=1623320217546")
      format("woff"),
    url("//at.alicdn.com/t/font_2534110_5323kulh1g4.ttf?t=1623320217546")
      format("truetype");
}

.iconfont {
  font-size: 16px;
  font-family: "iconfont" !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconplus:before {
  content: "\e627";
}

.iconminus:before {
  content: "\e628";
}

.iconpause:before {
  content: "\e625";
}

.iconplay-plain:before {
  content: "\e626";
}

.iconvolume:before {
  content: "\e624";
}
