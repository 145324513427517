// order:
// xgplayer-play  0
// xgplayer-playNext  1
// xgplayer-reload  1
// xgplayer-time  2
// xgplayer-placeholder 3
// xgplayer-volume 4
// xgplayer-definition 5
// xgplayer-bullet-btn 6
// xgplayer-textTrack 7
// xgplayer-playback 8
// xgplayer-download 9
// xgplayer-rotate 10
// xgplayer-screenShot 11
// xgplayer-airplay 11
// xgplayer-cssFullscreen 12
// xgplayer-fullscreen 13

// z-index:
// xgplayer-poster 100
// xgplayer-progress-point 100
// xgplayer-replay 105
// xgplayer-pip-active 110
// xgplayer-start 115
// xgplayer-enter 120
// xgplayer-error 125
// xgplayer-pip-lay 130
// xgplayer-pip-drag 135
// xgplayer-is-fullscreen 9999
// xgplayer-is-cssfullscreen 99999

.xgplayer-skin-xgplayer-gga {
  background: #000;
  width: 100%;
  height: 100%;
  position: relative;
  user-select: none;
  -ms-user-select: none;

  * {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  .xgplayer-none {
    display: none
  }

  &.xgplayer-rotate-fullscreen {
    position: absolute;
    top: 0;
    left: 100%;
    bottom: 0;
    right: 0;
    height: 100vw !important;
    width: 100vh !important;
    transform-origin: top left;
    transform: rotate(90deg);
  }

  &.xgplayer-is-fullscreen {
    width: 100% !important;
    height: 100% !important;
    // Undo any aspect ratio padding for fluid layouts
    padding-top: 0 !important;
    z-index: 9999;
  }

  &.xgplayer-is-fullscreen.xgplayer-inactive {
    cursor: none;
  }

  video {
    width: 100%;
    height: 100%;
    outline: none;
  }
}

.xgplayer-skin-xgplayer-gga .xgplayer-none {
  display: none;
}

@import "common/svg-url";
@import "common/animation";
@import "controls/airplay.scss";
@import "controls/controls.scss";
@import "controls/cssfullscreen.scss";
@import "controls/danmu.scss";
@import "controls/definition.scss";
@import "controls/download.scss";
@import "controls/enter.scss";
@import "controls/error.scss";
@import "controls/fullscreen.scss";
@import "controls/icon.scss";
@import "controls/live.scss";
@import "controls/loading.scss";
@import "controls/memoryPlay.scss";
@import "controls/miniplayer.scss";
@import "controls/pip.scss";
@import "controls/placeholder.scss";
@import "controls/play.scss";
@import "controls/playNext.scss";
@import "controls/playbackRate.scss";
@import "controls/poster.scss";
@import "controls/progress.scss";
@import "controls/reload.scss";
@import "controls/replay.scss";
@import "controls/rotate.scss";
@import "controls/screenShot.scss";
@import "controls/start.scss";
@import "controls/textTrack.scss";
@import "controls/time.scss";
@import "controls/tips.scss";
@import "controls/videoProxy.scss";
@import "controls/volume.scss";
