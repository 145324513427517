.xgplayer-skin-xgplayer-gga {
  .xgplayer-rotate {
    position: relative;
    order: 10;
    display: block;
    cursor: pointer;
    .xgplayer-icon {
      margin-top: 7px;
      width: 26px;
      div {
        position: absolute;
      }
    }
    .xgplayer-tips {
      margin-left: -22px;
      .xgplayer-tip-rotate {
        display: block;
      }
    }
    &:hover {
      opacity: 0.85;
      .xgplayer-tips {
        display: block;
      }
    }
  }
}
.xgplayer-lang-is-en {
  .xgplayer-rotate {
    .xgplayer-tips {
      margin-left: -26px;
    }
  }
}
.xgplayer-lang-is-jp {
  .xgplayer-rotate {
    .xgplayer-tips {
      margin-left: -38px;
    }
  }
}
