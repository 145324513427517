.xgplayer-skin-xgplayer-gga {
  .xgplayer-poster{
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-size: cover;
    background-position: center center;
  }
}

.xgplayer-skin-xgplayer-gga.xgplayer-nostart {
  .xgplayer-poster {
    display: block;
  }
}
