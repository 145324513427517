.xgplayer-skin-xgplayer-gga {
  .xgplayer-progress {
    display: block;
    position: absolute;
    height: 20px;
    line-height: 20px;
    left: 12px;
    right: 12px;
    outline: none;
    top: -15px;
    z-index: 35;
  }
  .xgplayer-progress-outer {
    background: rgba(255, 255, 255, 0.30);
    display: block;
    height: 3px;
    line-height: 3px;
    margin-top: 8.5px;
    width: 100%;
    position: relative;
    cursor: pointer;
  }
  .xgplayer-progress-cache,.xgplayer-progress-played {
    display: block;
    height: 100%;
    line-height: 1;
    position: absolute;
    left: 0;
    top: 0;
  }
  .xgplayer-progress-cache {
    width: 0;
    background: rgba(255, 255, 255, 0.50);
  }
  .xgplayer-progress-played {
    display: block;
    width: 0;
    background-image: linear-gradient(-90deg, #CBA942 0%, #AB8C2F 100%);
    border-radius: 0 1.5px 1.5px 0;
  }
  .xgplayer-progress-btn {
    display: none;
    position: absolute;
    left: 0px;
    top: -5px;
    width: 13px;
    height: 13px;
    border-radius: 30px;
    background: #FFFFFF;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.26);
    left: 100%;
    transform: translate(-50%, 0);
    z-index: 36;
  }
  .xgplayer-progress-point {
    position: absolute;
    &.xgplayer-tips {
      margin-left: 0;
      top: -25px;
      display: none;
      z-index: 100;
    }
  }
  .xgplayer-progress-dot {
    display: inline-block;
    position: absolute;
    height: 3px;
    width: 5px;
    top: 0px;
    background: white;
    border-radius: 6px;
    z-index: 16;
    .xgplayer-progress-tip {
      position: absolute;
      bottom: 200%;
      right: 50%;
      transform: translateX(50%);
      height: auto;
      line-height: 1.5;
      width: auto;
      // background: rgba(0, 0, 0, .3);
      border-radius: 6px;
      // border: 1px solid rgba(0, 0, 0, .8);
      cursor: default;
      white-space: wrap;
      word-break: break-all;
      width: 250px;
      display: none;
      background-color: #1F2937;
      // border-radius: 4;
      border: 1px solid #111827;
      padding: 8px;
      font-size: 13px;
      color: white;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);;
    }
  }
  .xgplayer-progress-dot-show {
    .xgplayer-progress-tip {
      display: block;
    }
  }
  .xgplayer-progress-thumbnail {
    position: absolute;
    box-sizing: border-box;
    &.xgplayer-tips {
      margin-left: 0;
      display: none;
      z-index: 99;
    }
  }
  .xgplayer-coverpreview {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    display: none;
    .xgplayer-coverpreview-outer {
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .xgplayer-coverpreview-thumbnail {
        display: block;
      }
      .xgplayer-coverpreview-point {
        display: block;
        text-align: center;
        font-family: PingFangSC-Regular;
        font-size: 11px;
        color: #ccc;
        padding: 2px 4px;
        span {
          color: #fff;
        }
      }
    }
  }
  .xgplayer-progress:hover,.xgplayer-progress:focus {
    .xgplayer-progress-outer {
      height: 6px;
      margin-top: 7px;
    }
    .xgplayer-progress-dot {
      height: 6px;
    }
    .xgplayer-progress-btn {
      display: block;
      top: -3px;
    }
  }
}

.xgplayer-skin-xgplayer-gga.xgplayer-volume-active {
  .xgplayer-progress {
    z-index: 15;
  }
}
.xgplayer-skin-xgplayer-gga.xgplayer-definition-active {
  .xgplayer-progress {
    z-index: 15;
  }
}
.xgplayer-skin-xgplayer-gga.xgplayer-texttrack-active {
  .xgplayer-progress {
    z-index: 15;
  }
}
.xgplayer-skin-xgplayer-gga.xgplayer-playbackrate-active {
  .xgplayer-progress {
    z-index: 15;
  }
}

.xgplayer-skin-xgplayer-gga.xgplayer-mobile {
  .xgplayer-progress-btn {
    display: block !important;
  }
  .xgplayer-progress:hover,.xgplayer-progress:focus{
    .xgplayer-progress-outer{
      height: 3px !important;
      margin-top: 8.5px !important;
    }
    .xgplayer-progress-btn {
      display: block !important;
      top: -5px !important;
    }
  }
}
