// @import "../node_modules/bootstrap/scss/functions";
// @import "../node_modules/bootstrap/scss/variables";
// @import "../node_modules/bootstrap/scss/mixins";
// @import "../node_modules/bootstrap/scss/forms";

// @import "../node_modules/bootstrap/scss/bootstrap";

// @import "./global.scss";

$hh_color: #ae2822;
$t_color: #666;
$border_color: #e8e6e1;
.header-font {
  font-size: 15px;
  // font-family: "Helvetica";
  font-weight: bold;
  color: #ffffff;
  line-height: 19px;
}
@mixin flex-box-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@mixin flex-box-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin flex-box-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-box-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@mixin flex-box-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin flex-box-flow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row wrap;
}
@mixin title {
  font-size: 24px;
  // font-family: "Helvetica";
  font-weight: 700;
  line-height: 19px;
}
