.xgplayer-skin-xgplayer-gga {
  .xgplayer-playbackrate {
    order: 8;
    width: 60px;
    height: 150px;
    z-index: 18;
    position: relative;
    display: inline-block;
    cursor: default;
    margin-top: -119px;
    ul {
      display: none;
      list-style: none;
      width: 78px;
      background: rgba(0, 0, 0, 0.54);
      border-radius: 1px;
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
      text-align: left;
      white-space: nowrap;
      z-index: 26;
      cursor: pointer;
      li {
        opacity: 0.7;
        font-family: PingFangSC-Regular;
        font-size: 11px;
        color: rgba(255, 255, 255, 0.80);
        position: relative;
        padding: 4px 0 4px 0px;
        text-align: center;
        &.selected{
          color: #fff;
          opacity: 1;
        }
        &:hover{
          color: #fff;
          opacity: 1;
        }
      }
      li:nth-child(1) {
        position: relative;
        margin-top: 12px;
      }
      li:last-child {
        position: relative;
        margin-bottom: 12px;
      }
    }
    .name {
      width: 60px;
      height: 20px;
      position: absolute;
      bottom: 0px;
      text-align: center;
      font-family: PingFangSC-Regular;
      font-size: 13px;
      background: rgba(0, 0, 0, .38);
      color: hsla(0, 0%, 100%, .8);
      border-radius: 10px;
      line-height: 20px;
    }
    span {
      position: relative;
      top: 19px;
      font-weight: bold;
      text-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
    }
    &:hover {
      opacity: 1;
    }
  }
}

.xgplayer-skin-xgplayer-gga.xgplayer-playbackrate-active {
  .xgplayer-playbackrate {
    ul {
      display: block;
    }
  }
}
