@import "./common.module.scss";

.my-page-box {
  .progress-bar {
    width: 373px;
    margin-top: 6px;
    height: 15px;
    background-color: #fff;
    border: 1px solid #ffeb00;
  }

  .process {
    width: 60%;
    height: 100%;
    background-color: #ab8c2f;
  }

  .lessons {
    @apply pb-4 relative;
    background-color: black;
    background-image: url("/images/course-bg-nebula.png");
    // background-size: cover;
    background-position-y: center;
    overflow: hidden;

    .progress-bar {
      margin: 10px auto 0;
    }

    .select-desc {
      @apply my-2;
      text-align: center;
      font-size: 15px;
      // font-family: "Helvetica-Bold";
      font-weight: 700;
      color: #ffffff;
      line-height: 19px;
      height: 19px;
    }
  }

  .team-empty {
    display: block;
    margin: 0;
    padding: 30px calc(50vw - 600px) 30px;
    width: 100%;
    background: #f7f7f7;
  }

  .carousel-wrap {
    position: relative;
    width: 1195px;
    margin: 30px auto 0px;

    :global {
      .swiper-container {
        display: flex;
        align-items: center;
      }

      .swiper-button-prev,
      .swiper-button-next {
        &:hover {
          opacity: 0.8;
        }

        &::after {
          color: #ab8c2f;
        }
      }

      .swiper-button-prev {
        left: -50px;
      }

      .swiper-button-next {
        right: -50px;
      }
    }
  }

  .events {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #f7f7f7;
    padding-bottom: 30px;
  }

  .events-title {
    @apply mb-2;
    margin-top: 35px;
    font-size: 20px;
    // font-family: "Helvetica";
    font-weight: 700;
    line-height: 19px;
    text-align: center;
  }

  .players-wrapper {
    background: #f7f7f7;
    padding-bottom: 40px;
  }

  .players {
    @apply py-4 container mx-auto;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    &-item {
      position: relative;
      padding-bottom: 10px;
      margin: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      width: 150px;
      height: 179px;
      background: #ab8c2f;
      border-top: 40px solid #e9e9e9;

      &.gray {
        background: #9e9e9e;
      }
    }

    .iconC {
      position: absolute;
      top: -50px;
      right: -10px;
      display: block;

      svg {
        width: 21px;
        height: 21px;
      }

      &.hide {
        display: none;
      }
    }

    .avatar {
      margin-top: -26px;
      width: 92px;
      height: 92px;
      border-radius: 50%;
    }

    .divider {
      width: 154px;
      border-top: 1px solid #fff;
    }

    .name {
      font-size: 15px;
      // font-family: "Helvetica";
      font-weight: bold;
      color: #ffffff;
      line-height: 32px;
    }

    .text {
      font-size: 14px;
      // font-family: "Helvetica";
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
    }
  }
}

.upload-wrap {
  font-size: 14px;
  // font-family: Helvetica;
  font-weight: 400;
  // line-height: 18px;
  // text-align: center;
  svg {
    display: block;
    margin: auto;
  }

  &.raw-content {
    *,
    *::before,
    *::after {
      text-align: left;
      max-width: 100%;
      box-sizing: border-box;
      font-weight: normal;
      // font-family: -apple-system-, "Helvetica Bold", Roboto, Oxygen, sans-serif;
    }
  }

  .title {
    margin: 80px auto 10px;
    font-size: 15px;
    // font-family: Helvetica;
    font-weight: 700;
    line-height: 19px;
  }

  .textarea {
    padding: 10px;
    width: 485px;
    height: 140px;
    border: 2px solid #eee;
    outline: none;
    resize: none;
  }

  .box {
    position: relative;
    display: block;
    width: 166px;
    height: 100%;
    cursor: pointer;
    margin: auto;
    text-align: center;

    :global {
      .icon {
        margin: 50px auto 0px;
        width: 73px;
        height: 44px;
      }
    }

    .upload {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      font-size: 0;
      opacity: 0;
      cursor: pointer;
    }
  }

  .hide {
    display: none;
  }

  .error {
    @apply text-red-500 text-center;
  }

  .upload-progress-wrap {
    padding-top: 20px;

    .done {
      @apply text-green-600;
      padding: 10px 0 0;
      height: 11px;
      font-size: 13px;
      text-align: center;
      font-weight: 700;
    }

    .error {
      text-align: center;
      padding: 10px 0 0;
    }
  }

  .upload-tip {
    margin: 40px auto;
    // color: red;
    font-weight: bold;
    text-align: center;
  }

  .file-size-warning {
    margin-top: 20px;
    text-align: center;
    display: none;

    &.shown {
      display: block;
    }
  }
}

.start-button {
  display: block;
  margin: 20px auto 0;
  font-size: 15px;
  // font-family: Helvetica Bold;
  font-weight: 400;
  color: #ffffff;
  background: #ab8c2f;
  outline: none;
  border: none;
  width: 159px;
  height: 48px;
  line-height: 48px;
  cursor: pointer;

  &:hover:enabled {
    opacity: 0.8;
  }

  &:disabled {
    background-color: #888;
    cursor: auto;
  }
}

@media (min-width: 768px) {
  .my-page-box .players {
    width: 785px;
  }
}

@media (min-width: 1280px) {
  .my-page-box .players {
    width: 1195px;
  }
}
