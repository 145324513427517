.gvp-playback-progress {
  &-container {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  &-time {
    margin-left: 1rem;
    color: #333;
  }

  &-knob {
    position: absolute;
    top: 0;
    z-index: 1;
    width: 8px;
    height: calc(100%);
    background-color: #ab8c2f;
    border: 1px solid #f0f0f0;
    border-radius: 3px;
    cursor: pointer;
    &:hover,
    &:active {
      background-color: #c5a135;
    }
  }
  &-bar {
    flex: 1;
    width: 100%;
    height: 10px;
    cursor: pointer;
    appearance: none;
    &::-webkit-progress-bar {
      height: 10px;
      background-color: #f0f0f0;
    }
    &::-webkit-progress-value {
      height: 10px;
      background-color: #ab8c2f;
    }
    &::-moz-progress-bar {
      height: 10px;
      background-color: #ab8c2f;
    }
  }
}

.gvp-volume {
  &-icon {
    color: #777;
    &:hover {
      color: #c5a135;
    }
    &__disabled {
      color: #f0f0f0;
      &:hover {
        color: #f0f0f0;
      }
    }
  }
  &-wrapper {
    padding: 0.4rem 0.6rem;
  }
  &-control {
    position: relative;
    color: #777;
    cursor: pointer;
    &-icon:hover {
      color: #333;
    }
  }
  &-opener {
    position: absolute;
    width: 100px;
    top: 0;
    right: 0;
    z-index: 99;
    padding: 4px 8px;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 4px;
    transform: translate(0, -100%);
    visibility: hidden;
    opacity: 0;
    &__show {
      visibility: visible;
      opacity: 1;
    }
    &-inner {
      font-size: 14px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-items: center;
      div {
        flex: 1;
        text-align: center;
      }
      span {
        padding: 4px;
        cursor: pointer;
      }
    }
  }
}
