.xgplayer-skin-xgplayer-gga {
  .xgplayer-cssfullscreen, .xgplayer-cssfullscreen-img {
    position: relative;
    order: 12;
    display: block;
    cursor: pointer;
    .xgplayer-icon {
      width: 32px;
      margin-top: 5px;
      div {
        position: absolute;
      }
      .xgplayer-icon-requestfull {
        display: block;
      }
      .xgplayer-icon-exitfull {
        display: none;
      }
    }
    .xgplayer-tips {
      margin-left: -40px;
      .xgplayer-tip-requestfull {
        display: block;
      }
      .xgplayer-tip-exitfull {
        display: none;
      }
    }
    &:hover {
      opacity: 0.85;
      .xgplayer-tips {
        display: block;
      }
    }
  }
}

.xgplayer-skin-xgplayer-gga.xgplayer-is-cssfullscreen {
  .xgplayer-cssfullscreen, .xgplayer-cssfullscreen-img {
    .xgplayer-icon {
      .xgplayer-icon-requestfull {
        display: none;
      }
      .xgplayer-icon-exitfull {
        display: block;
      }
    }
    .xgplayer-tips {
      margin-left: -47px;
      .xgplayer-tip-requestfull {
        display: none;
      }
      .xgplayer-tip-exitfull {
        display: block;
      }
    }
  }
}

.xgplayer-skin-xgplayer-gga.xgplayer-is-fullscreen {
  .xgplayer-cssfullscreen, .xgplayer-cssfullscreen-img {
    display: none;
  }
}

.xgplayer-skin-xgplayer-gga.xgplayer-is-cssfullscreen {
  position: fixed !important;
  left: 0 !important;
  top: 0 !important;
  width: 100%!important;
  height: 100%!important;
  z-index: 99999 !important;
}

.xgplayer-lang-is-en {
  .xgplayer-cssfullscreen, .xgplayer-cssfullscreen-img {
    .xgplayer-tips {
      margin-left: -46px;
    }
  }
}
.xgplayer-lang-is-en.xgplayer-is-cssfullscreen {
  .xgplayer-cssfullscreen, .xgplayer-cssfullscreen-img {
    .xgplayer-tips {
      margin-left: -46px;
    }
  }
}

.lang-is-jp {
  .xgplayer-cssfullscreen, .xgplayer-cssfullscreen-img {
    .xgplayer-tips {
      margin-left: -120px;
    }
  }
}
.lang-is-jp.xgplayer-is-cssfullscreen {
  .xgplayer-cssfullscreen, .xgplayer-cssfullscreen-img {
    .xgplayer-tips {
      margin-left: -60px;
    }
  }
}
